<template>
  <div class="rounded">
    <div v-if="id" class="bg-white w-full px-1.5" id="bar">
      <p class="sm:container text-gray-dark text-md pt-2 truncate">Ordini - {{ customer.company_name }}</p>
    </div>
    <div v-else class="bg-white w-full px-1.5" id="bar">
      <p class="sm:container text-gray-dark text-md pt-2">Ordini</p>
    </div>
    <div class="bg-white z-10 text-gray-dark text-xs md:text-sm p-1.5 space-x-3 w-full" id="header">
      <div class="flex flex-col space-y-1.5 w-full sm:container">
        <div class="flex w-full items-center justify-center space-x-2">
          <button v-if="id" @click="$router.go(-1)" class="text-md text-gray-dark cursor-pointer hover:text-primary"><i
              class="fa-solid fa-arrow-left"></i></button>
          <div class="flex flex-grow bg-gray-light items-center pr-1 space-x-1 rounded">
            <input class="border-0 bg-gray-light" placeholder="Cerca ordine" @keyup.enter="searchLoad"
                   type="search" v-model="search">
            <i class="fa-solid rounded pr-1 fa-magnifying-glass cursor-pointer" @click="searchLoad"></i>
          </div>
          <p class="text-base text-gray-dark cursor-pointer mr-2 hover:text-primary" @click="open_filter_slideover"><i
              class="fa-solid fa-filter"></i></p>
        </div>
        <Transition
            enter-active-class="duration-300 ease-in-out"
            enter-from-class="transform-all opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="transform-all opacity-0"
        >
          <div
              v-if="filters_search.start_date || filters_search.end_date || filters_search.web !== null || filters_search.type !== null || searched"
              class="flex flex-wrap space-x-2">
            <TransitionGroup
                enter-active-class="duration-300 ease-in-out"
                enter-from-class="transform-all opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="transform-all opacity-0"
            >
                    <span v-if="filters_search.start_date" @click="remove_start_date"
                          class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                        class="fa-solid fa-xmark"></i> Dal {{
                        $luxon(filters_search.start_date)
                      }} </span>
              <span v-if="filters_search.end_date" @click="remove_end_date"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> Al {{
                  $luxon(filters_search.end_date)
                }} </span>
              <span v-if="is_super_agent && agents && filters_search.sales_agent_id !== null"
                    @click="remove_sales_agent"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> Agente: {{
                  agents.filter(v => v.id === filters_search.sales_agent_id)[0].company_name
                }} </span>
              <span v-if="filters_search.web !== null" @click="remove_status"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> Origine: {{
                  filters_search.web ? 'Web' : 'Agente'
                }} </span>
              <span v-if="filters_search.type !== null" @click="remove_type"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> Tipo: {{
                  types.filter(v => v.value === filters_search.type)[0].label
                }} </span>
              <span v-if="searched" @click="remove_search"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> {{ searched }} </span>
            </TransitionGroup>
          </div>
        </Transition>
      </div>
    </div>


    <div class="flex space-x-1.5">
      <button v-if="id" @click="create"
              class="btn btn-primary text-xs flex items-center justify-center h-[3.5rem] w-[3.5rem] z-10 rounded-full fixed bottom-[5rem] right-2 md:bottom-md md:right-3 lg:right-[3.5rem] shadow">
        <i class="fas fa-plus"></i></button>
      <router-link v-else :to="{name: 'orders.create'}"
                   class="btn btn-primary text-xs flex items-center justify-center h-[3.5rem] w-[3.5rem] z-10 rounded-full fixed bottom-[5rem] right-2 md:bottom-md md:right-3 lg:right-[3.5rem] shadow">
        <i class="fas fa-plus"></i></router-link>
    </div>
    <div class="lg:container mt-2 p-1.5">
      <Transition
          enter-active-class="duration-700 ease-in-out"
          enter-from-class="transform-all opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-200 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="transform-all opacity-0"
      >
        <div class="grid md:grid-cols-2 mb-2 gap-0.5 rounded container" v-if="!kpi_loading">
          <div class="flex items-center space-x-1.5 p-2 bg-white rounded">
            <div class="h-sm w-sm flex items-center justify-center bg-primary-light rounded text-white text-md"><i
                class="fa-solid fa-euro-sign"></i></div>
            <div>
              <p>Totale ordini</p>
              <p class="text-gray-dark font-bold text-md">{{ kpi.total_orders || '-' }}</p>
            </div>
          </div>
          <div class="flex items-center space-x-1.5 p-2 bg-white rounded">
            <div class="h-sm w-sm flex items-center justify-center bg-primary-light rounded text-white text-md"><i
                class="fa-solid fa-cart-shopping"></i></div>
            <div>
              <p>Ordini</p>
              <p class="text-gray-dark font-bold text-md">{{ kpi.total }}</p>
            </div>
          </div>
        </div>
      </Transition>

      <div v-if="kpi_loading" class="grid md:grid-cols-2 mb-2 gap-0.5 rounded container">
        <div class="animate-pulse flex items-center space-x-1.5 p-2 bg-white rounded-t lg:rounded-l">
          <div class="h-sm w-sm flex items-center justify-center bg-primary-light rounded text-white text-md"></div>
          <div class="w-full space-y-1.5">
            <div class="rounded-full bg-gray h-1.5 w-1/5"></div>
            <div class="rounded-full bg-gray h-2 w-2/5"></div>
          </div>
        </div>
        <div class="animate-pulse flex items-center space-x-1.5 p-2 bg-white rounded-t lg:rounded-l">
          <div class="h-sm w-sm flex items-center justify-center bg-primary-light rounded text-white text-md"></div>
          <div class="w-full space-y-1.5">
            <div class="rounded-full bg-gray h-1.5 w-2/5"></div>
            <div class="rounded-full bg-gray h-2 w-1/5"></div>
          </div>
        </div>
      </div>
      <div class="bg-white border border-thin border-gray-light rounded-xl">
        <div class="border border-thin border-gray-light rounded-xl flex-grow overflow-x-auto block">
          <table class="table-auto min-w-full h-full" id="list" ref="list">
            <tr class="text-gray-dark">
              <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light rounded-tl">ID Ordine</th>
              <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light" v-if="!id">Cliente</th>
              <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light hidden md:table-cell" v-else>Indirizzo</th>
              <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light hidden md:table-cell">Tipo</th>
              <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Stato
              </th>
              <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light hidden md:table-cell">Metodo
                pagamento
              </th>
              <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Totale</th>
              <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light hidden lg:table-cell">Ultima
                Modifica
              </th>
              <th class="font-normal text-center px-1.5 py-1.5 bg-gray-light rounded-tr">Azioni</th>
            </tr>
            <TransitionGroup tag="tbody"
                             enter-active-class="duration-700 ease-in-out"
                             enter-from-class="transform-all opacity-0"
                             enter-to-class="opacity-100"
                             leave-active-class="duration-100 ease-in"
                             leave-from-class="opacity-100"
                             leave-to-class="transform-all opacity-0"
            >
              <tr class="border-b border-b-thin  text-sm"
                  v-if="data.values"
                  v-for="order in data.values" :key="order.id"
                  :class="order.status.value === 0 || order.status.value === 1 ? (order.type === 'Bozza' ? 'bg-[#fafad9] border-[#fae8ca]' : 'bg-white border-gray-light') : 'bg-[#faddd9] border-[#fae8ca]'">
                <td class="p-1.5 flex flex-col space-y-1">
                  <tippy :content="order.status.description" v-if="order.type !== 'Bozza'">
                    <i v-if="order.status.value === 0" class="fa-solid fa-hourglass-start"></i>
                    <i v-else-if="order.status.value === 1" class="fa-solid fa-thumbs-up"></i>
                    <i v-else class="fa-solid fa-xmark"></i>
                    {{ order.order_id || order.id }}
                  </tippy>
                  <span v-else>{{ order.order_id || order.id }}</span>
                  <small class="lg:hidden">{{order.updated_at}}</small>
                </td>
                <td class="p-1.5" v-if="!id">
                  <div class="flex flex-col">
                    <span class="text-xs mb-0.5"
                          v-if="is_super_agent">Seguito da <b class="text-primary">{{ order.sales_agent }}</b></span>
                    <router-link :to="{name: 'customers.show', params: {id: order.customer_id}}">
                      <p class="font-base font-medium text-gray-dark">{{ order.customer }}</p>
                    </router-link>
                    <span class="text-xs mt-1">Presso: {{ order.address ? order.address : 'LED ITALIA SRL' }}</span>
                  </div>
                </td>
                <td class="p-1.5 hidden md:table-cell" v-else>{{ order.address ? order.address : 'LED ITALIA SRL' }}</td>
                <td class="p-1.5 whitespace-nowrap hidden md:table-cell">{{ order.type }} <i v-if="order.web"
                                                                                             class="fa-solid fa-globe text-base"></i>
                </td>
                <td class="p-1.5 overflow-ellipsis text-xs">
                  <template v-for="shipment in order.shipments">
                    <p v-for="tracking in shipment">{{ tracking }}</p>
                  </template>
                </td>
                <td class="p-1.5 hidden md:table-cell">{{ order.payment_method }}</td>
                <td class="p-1.5">{{ order.total_price }}</td>
                <td class="p-1.5 hidden lg:table-cell">{{ order.updated_at }}</td>
                <td class="p-1.5 text-center flex flex-nowrap h-full items-center justify-between text-base">
                  <router-link
                      :to="order.type === 'Bozza' ? {name: 'orders.edit', params: {id: order.id}} : {name: 'orders.show', params: {id: order.id}}"
                      class="text-primary-light hover:text-primary"><i class="fas fa-arrow-right"></i>
                  </router-link>
                  <button v-if="order.type === 'Bozza'" class="text-secondary hover:text-secondary-light"
                          @click="open_delete_draft_modal(order.id)"><i class="fa-solid fa-trash"></i></button>
                </td>
              </tr>
            </TransitionGroup>
          </table>
        </div>
      </div>
    </div>
    <div v-if="loading" class="w-full flex justify-center items-center pt-0.5 pb-2">
      <div class="flex justify-center items-center bg-white h-4 w-4 rounded-full">
        <p class="text-primary"><i class="fa-solid fa-spinner animate-spin"></i></p>
      </div>
    </div>
    <div v-if="end" class="h-[7rem] w-full xl:hidden"></div>
    <navbar></navbar>
    <modal ref="delete_draft_modal">
      <div class="flex flex-col space-y-1.75 p-1">
        <span class="text-md font-bold text-primary-light">Attenzione</span>
        <span class="text-gray-dark">Sei sicuro di voler eliminare la bozza dell'ordine {{
            draft_want_delete
          }}?</span>
        <span class="text-gray-dark">Premendo <span
            class="text-primary-light">Elimina</span> la bozza {{ draft_want_delete }} verrà eliminata.</span>
        <div class="flex w-full justify-end">
          <div class="flex space-x-2">
            <button class="btn btn-outline-primary" @click="$refs.delete_draft_modal.close()">Annulla</button>
            <button class="btn btn-secondary" @click="delete_draft">Elimina</button>
          </div>
        </div>
      </div>
    </modal>
    <modal ref="info">
      <div class="flex flex-col space-y-1.75 p-1">
        <span class="text-md font-bold text-primary-light">Attenzione</span>
        <span
            class="text-gray-dark">Per visualizzare le Bozze e gli Ordini creati <b>precedentemente</b> all'anno corrente, togliere il filtro pre applicato</span>
        <div class="flex w-full justify-end">
          <div class="flex space-x-2">
            <button class="btn btn-primary" @click="ok_info">Ok, Capito!</button>
          </div>
        </div>
      </div>
    </modal>
    <slideover ref="filter" title="Filtri">
      <template v-slot:content>
        <ic>
          <label for="start_date">Inizio</label>
          <input type="date" id="start_date" v-model="filters.start_date" :max="filters.end_date">
        </ic>
        <ic>
          <label for="end_date">Fine</label>
          <input type="date" id="end_date" v-model="filters.end_date" :min="filters.start_date">
        </ic>
        <ic v-if="is_super_agent">
          <label for="agent">Agenti</label>
          <select v-model="filters.sales_agent_id" id="agent">
            <option :value="null">Tutti</option>
            <option v-for="agent in agents" :value="agent.id">{{ agent.company_name }}</option>
          </select>
        </ic>
        <ic>
          <label for="type">Tipo</label>
          <select v-model="filters.type" id="type">
            <option v-for="type in types" :value="type.value">{{ type.label }}</option>
          </select>
        </ic>
        <ic>
          <label for="origin">Origine</label>
          <select v-model="filters.web" id="origin">
            <option v-for="origin in origins" :value="origin.value">{{ origin.label }}</option>
          </select>
        </ic>
      </template>
      <template v-slot:actions>
        <button class="btn btn-primary" @click="apply_filters()">Applica</button>
      </template>
    </slideover>
  </div>

</template>

<script>
import OrderPagination from "./order-pagination.vue";
import navbar from "../../components/navbar.vue";
import Modal from "../../components/modal.vue";
import OrderFilters from "./order-filters.vue";
import {Tippy} from "vue-tippy";
import 'tippy.js/dist/tippy.css' // optional for styling

export default {
  name: "order-index",
  components: {OrderFilters, Modal, OrderPagination, navbar, Tippy},
  props: ['id'],
  data: () => ({
    customer: {
      company_name: null
    },
    kpi: {
      total: null,
      total_orders: null,
    },
    data: {
      values: []
    },
    search: null,
    searched: null,
    page: 1,
    loading: false,
    kpi_loading: true,
    draft_want_delete: null,
    filters: {
      start_date: null,
      end_date: null,
      sales_agent_id: null,
      type: null,
      web: null
    },
    filters_search: {
      start_date: null,
      end_date: null,
      sales_agent_id: null,
      type: null,
      web: null
    },
    origins: [
      {value: null, label: 'Tutti'},
      {value: true, label: 'Web'},
      {value: false, label: 'sotto Agente'},
    ],
    types: [
      {value: null, label: 'Tutti'},
      {value: 0, label: 'Bozze'},
      {value: 2, label: 'Creati'},
    ],
    agents: [],
    is_super_agent: false,
    end: false
  }),
  methods: {
    kpi_load() {
      axios.get('/ordini/orders', {
        params: {
          search: null,
          page: this.page,
          start_date: this.filters_search.start_date,
          end_date: this.filters_search.end_date,
          sales_agent_id: this.filters_search.sales_agent_id,
          type: this.filters_search.type,
          web: this.filters_search.web,
          customer_id: this.id,
          query_type: 'kpi'
        }
      }).then(response => {
        this.kpi = response.data;
        this.kpi_loading = false;
      })
    },
    load() {
      this.loading = true;
      axios.get('/ordini/orders', {
        params: {
          search: null,
          page: this.page,
          start_date: this.filters_search.start_date,
          end_date: this.filters_search.end_date,
          sales_agent_id: this.filters_search.sales_agent_id,
          type: this.filters_search.type,
          web: this.filters_search.web,
        }
      })
          .then(response => {
            this.end = !response.data.data.length;
            this.page++;
            if (!this.end) response.data.data.forEach(val => this.data.values.push(val));
            this.loading = false;
          })
    },
    searchLoad() {
      let load = this.$loading.show();
      this.loading = true;
      this.kpi_loading = true;
      this.end = false;
      axios.get('/ordini/orders', {
        params: {
          search: this.search,
          page: 0,
          start_date: this.filters_search.start_date,
          end_date: this.filters_search.end_date,
          sales_agent_id: this.filters_search.sales_agent_id,
          type: this.filters_search.type,
          web: this.filters_search.web,
          customer_id: this.id
        }
      })
          .then(response => {
            this.data['values'] = response.data.data;
            this.page = 1;
            this.end = !response.data.data.length;
            this.loading = false;
            this.searched = this.search;
            load.hide();
          })
      this.kpi_load();
    },
    update() {
      this.loading = true;
      axios.get('/ordini/orders', {
        params: {
          search: this.search,
          page: 0,
          start_date: this.filters_search.start_date,
          end_date: this.filters_search.end_date,
          sales_agent_id: this.filters_search.sales_agent_id,
          type: this.filters_search.type,
          web: this.filters_search.web,
          customer_id: this.id
        }
      })
          .then(response => {
            response.data.data.reverse().forEach(val => {
              if (!this.data.values.includes(val))
                this.data.values.unshift(val);
            });
            this.loading = false;
          })
    },
    loadScrolling() {
      let header = document.getElementById('header');
      let sticky = header.offsetTop;
      let bar = document.getElementById('bar');
      window.onscroll = () => {
        if (window.pageYOffset > sticky) {
          header.classList.add('sticky');
          header.classList.add('top-0');
          bar.classList.remove('top-0');
        } else {
          bar.classList.add('top-0');
          header.classList.remove('sticky');
          header.classList.remove('top-0');
        }
        let list = this.$refs.list.getBoundingClientRect();
        let bottomOfWindow = list.bottom < window.innerHeight;
        if (bottomOfWindow && !this.end && !this.loading) {
          this.page++;
          this.load();
        }
      }
    },
    ok_info() {
      localStorage['ok_info'] = true;
      this.$refs.info.close();
    },
    async open_filter_slideover() {
      this.$refs.filter.open();
      if (localStorage['is_super_agent'] === 'true' && !this.agents.length) {
        this.$refs.filter.set_loading(true);
        this.agents = (await axios.get('/sales-agent')).data;
        this.$refs.filter.set_loading(false);
      }
    },
    apply_filters(close = true) {
      this.page = 1;
      this.data.values = [];
      this.filters_search = JSON.parse(JSON.stringify(this.filters));
      this.kpi_loading = true;
      if (close) this.$refs.filter.close();
      this.searchLoad();
    },

    open_delete_draft_modal(order) {
      this.draft_want_delete = order;
      this.$refs.delete_draft_modal.open();
    },

    async delete_draft() {
      try {
        let l = this.$loading.show()
        await axios.delete('/ordini/orders/' + this.draft_want_delete);
        this.data.values = this.data.values.filter(v => v.id !== this.draft_want_delete);
        l.hide();
      } catch (e) {
      }
      this.$refs.delete_draft_modal.close();
    },
    remove_start_date() {
      this.filters.start_date = null;
      this.apply_filters(false);
    },
    remove_end_date() {
      this.filters.end_date = null;
      this.apply_filters(false);
    },
    remove_sales_agent() {
      this.filters.sales_agent_id = null;
      this.apply_filters(false);
    },
    remove_status() {
      this.filters.web = null;
      this.apply_filters(false);
    },
    remove_type() {
      this.filters.type = null;
      this.apply_filters(false);
    },
    remove_search() {
      this.search = null;
      this.searched = null;
      this.apply_filters(false);
    },
    go(orderId){
      this.$router.push({name: 'orders.edit', params: {id: orderId}, query: {from: this.$props.id}});
    },
    async create() {
      let l = this.$loading.show();
      try {
        let response = (await axios.post('/ordini/orders', {customer_id: this.id}));
        console.log(response);
        let orderId = response;
        l.hide();
        this.go(orderId);
      } catch (e) {
        this.$toast.error(e.response.data.message);
      }
      l.hide();
    },
  },
  beforeMount() {
    this.filters_search.start_date = new Date(new Date().getFullYear(), 0, 2).toISOString().split('T')[0];
    this.searchLoad();
    this.filters.start_date = this.filters_search.start_date;
  },
  async mounted() {
    if (typeof localStorage['is_super_agent'] === 'undefined') {
      this.loading = true;
      localStorage['is_super_agent'] = (await axios.get('/super-agent')).data;
      this.loading = false;
    }
    this.is_super_agent = localStorage['is_super_agent'] === 'true';
    if (this.$props.id) {
      let l = this.$loading.show();
      try {
        this.customer = (await axios.get('/clienti/customers/' + this.$props.id)).data;
        l.hide();
      } catch (e) {
        this.$toast.error(e.response.data.message || e);
        l.hide();
        this.$router.push({name: 'customers.index'});
      }
    }
    this.loadScrolling();
    if (localStorage['ok_info'] !== 'true') this.$refs.info.open();
  },
  beforeDestroy() {
    window.onscroll = () => null
  },
  beforeUnmount() {
    window.onscroll = () => null;
  },
  watch: {
    kpi_loading: function () {
      if (this.kpi_loading) {
        this.kpi_load();
      }
    }
  }
}
</script>

<style scoped>

</style>
