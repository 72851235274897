<template>
    <div class="grid xl:grid-cols-4 grid-cols-1 gap-1 xl:gap-4 w-full items-center">
        <div class="flex items-center justify-between space-x-1">
            <router-link :to="{name: 'products.show', params: {id: item.id, order_id: order_id}}"
                         class="flex items-center space-x-1 cursor-pointer">
                <img :src="item.thumbnail" :alt="'sku '+item.sku" class="h-md">
                <div class="flex flex-col space-y-1 text-sm">
                    <span class="text-primary font-bold order-1">SKU {{ item.sku }}</span>
                    <span class="text-gray-dark overflow-ellipsis block order-3">{{ item.description }}</span>
                    <span :class="item['availability']['color']"
                          class="font-bold text-left lg:hidden order-2">{{ item.availability.text }}</span>
                </div>
            </router-link>
            <button class="text-md text-primary pr-1.5" @click="openHistory()"><i class="fa-solid fa-chart-line"></i>
            </button>
        </div>
        <span :class="item['availability']['color']"
              class="font-bold text-left hidden lg:block">{{ item.availability.text }}</span>
        <div class="flex space-x-1 xl:space-x-2 justify-end xl:col-span-2">
            <ic class="flex flex-col">
                <label class="text-gray-dark text-xs" for="quantity">Quantità</label>
                <input type="text" v-model="quantity" @focus="$event.target.select()"
                       id="quantity" @change="change_quantity_line">
                <span class="text-[0.7rem] text-gray-dark" v-if="item.raw_quantity">
                                            (<span v-if="item.raw_quantity[0]">IT: {{
                    Math.max(0, item.raw_quantity[0].quantity)
                    }} {{ item.base_uom || 'pz' }}, </span>
                                            <span v-if="item.raw_quantity[1]">EU: {{
                                                Math.max(0, item.raw_quantity[1].quantity)
                                                }} {{ item.base_uom || 'pz' }}</span>)
              </span>
            </ic>
            <ic class="flex flex-col flex-grow">
                <label class="text-gray-dark text-xs" for="discount">Sconto</label>
                <select v-if="!item.is_net_price" v-model="discount" id="discount"
                        @change="update_item(item.id, item.quantity, discount, true)"
                        class="border-solid border-gray-light bg-transparent min-w-[5rem]">
                    <option v-for="discount in discounts" :value="discount">{{ discount }}%</option>
                </select>
                <span v-else>Prezzo netto</span>
                <span class="text-primary self-end md:hidden">{{ item.line_price }}</span>
                <span class="hidden md:flex flex-col md:flex-row justify-between items-center p-0.5"><span
                        class="text-xs"
                        :class="item.promo_price? 'text-flags-promo' : 'text-gray'">{{ item.promo_price || item.base_price }}/{{ item.base_uom }}</span><span
                        class="text-primary lg:text-sm">{{ item.line_price }} <span
                        class="text-sm lg:text-xs">({{ item.unit_price }}/{{ item.base_uom }})</span></span></span>
            </ic>
            <div class="flex flex-col space-y-1">
                <span class="text-gray-dark text-xs">&#8205;</span>
                <span @click="delete_line()" class="btn text-gray hover:bg-white cursor-pointer hover:text-secondary"><i
                        class="fa-solid fa-times"></i></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "order-products-line",
    props: {
        item: {},
        update_item: null,
        order_id: null,
        discounts: [],
    },
    data: () => ({
        quantity: 1,
        discount: 0,
        discounts: [50, 55, 60, 62],
    }),
    mounted() {

        this.quantity = this.$props.item.quantity;
        this.discount = this.$props.item.discount;
        this.discounts = this.$props.discounts;
        if (!this.discounts.filter((v) => v === this.discount).length) {
            this.discounts.push(this.discount);
            this.discounts.sort();
        }

    },
    methods: {
        async change_quantity_line() {
            await this.$props.update_item(this.$props.item.id, this.quantity, this.discount, true);
        },
        async delete_line() {
            await this.$props.update_item(this.$props.item.id, 0, this.discount, true);
        },
        openHistory() {
            this.$emitter.emit('open_history', this.$props.item)
        }
    },
    watch: {
        'item.quantity': function () {
            this.quantity = this.$props.item.quantity;
        }
    }
}
</script>

<style scoped>

</style>
